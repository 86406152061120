// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7Qk4C4nt{background-color:var(--color-primary);width:100%}.MjLR1btY{align-items:center;color:var(--color-bg);display:flex;justify-content:flex-end;margin:0 auto;max-width:var(--content-width);padding:var(--spacing-lg) var(--spacing-sm);position:relative}.MjLR1btY>*+*{margin-left:max(.84rem,min(1.25vw,1.5rem))}._1Kq3soYc{left:var(--spacing-lg);position:absolute;top:var(--spacing-lg);z-index:1}@media(max-width:667.98px){._1Kq3soYc{top:calc(var(--spacing-lg)*2 + 2.5rem);width:50vw}}.hMnsy2SS{flex-grow:1}.G5E8LviZ{background-color:var(--color-primary);border:1px solid!important;color:inherit!important;z-index:2}@media(max-width:947.98px){.G5E8LviZ{height:2.5rem!important;padding:0!important;width:2.5rem!important}}.XbzJP8nB{--size:1.3em!important}.G5E8LviZ>div{align-items:center;display:flex}.G5E8LviZ>div>*+*{margin-left:.5em}@media(max-width:947.98px){.G5E8LviZ>div>span{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "_7Qk4C4nt",
	"content": "MjLR1btY",
	"logo": "_1Kq3soYc",
	"plug": "hMnsy2SS",
	"btn": "G5E8LviZ",
	"btnIcon": "XbzJP8nB"
};
module.exports = ___CSS_LOADER_EXPORT___;
