
import { mapState, mapGetters } from "vuex"
import layoutMixin from "@/mixins/layout"

export default layoutMixin.extend({
  middleware: "auth",

  computed: {
    ...mapState("user", ["unreadMessagesCount"]),
    ...mapGetters("user", [
      "user",
      "isAdmin",
      "isCaptain",
      "isPayable",
      "hasTeam",
    ]),

    isPaymentVisible (): boolean {
      return this.isPayable
    },

    tabs (): string[] {
      const tabs = [
        "profile",
        // "calendar",
        // "chat",
        // "recommended",
      ]

      if (this.isCaptain) tabs.push("team/create")
      tabs.push("team")
      if (this.hasTeam) tabs.push("nominants")

      // if (this.isAdmin)
      //   if (this.user.status.alias === "curator") tabs.push("teams")

      // if (this.isPaymentVisible) tabs.push("payment")

      // tabs.push("vote")

      return tabs
    },

    tab: {
      get (): string {
        const { tabs } = this
        return tabs.find(t => this.$route.path.includes(t)) || tabs[0]
      },
      set (tab: string) {
        // FIXME
        if (!this.$route.path.includes(tab))
          this.$router.push(this.localePath(`/user/${tab}`))
      },
    },
  },
})
