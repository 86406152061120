
export default {
  props: {
    icon: { type: String, default: "" },
    iconPaint: { type: Array, default: () => [] }, // AppIcon.paint
    theme: { type: String, default: "" }, // primary / danger
    variant: { type: String, default: "" }, // bg / outline
    to: { type: [String, Object], default: "" },
    href: { type: String, default: "" },
    isLoading: { type: Boolean, default: false },
    forceSlot: { type: Boolean, default: false },
  },

  computed: {
    is () {
      if (this.to) return "nuxt-link"
      if (this.href) return "a"
      return "button"
    },

    attrs () {
      const { to, href } = this
      if (to) return { to }
      if (href) return { href }
      return { type: "button" }
    },
  },
}
