export const actions = {
  async nuxtServerInit ({ commit, dispatch }) {
    const votingUntil = Date.UTC(2024, 11, 30, 21) // 2024-12-31T00:00+03:00
    const votingDisabledViaRoute =
      this.app.router.currentRoute.fullPath === "/nominants?disabled=1"
    commit("app/SET_VOTING_CLOSED",
      Date.now() >= votingUntil || votingDisabledViaRoute)

    await Promise.all([
      dispatch("app/FETCH_EVENT"),
      // dispatch("app/FETCH_MENU"),
      // dispatch("live/FETCH_HALLS"),
    ])
  },
}
