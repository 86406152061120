import groupBy from "lodash/groupBy"
import { isUserAdmin, isUserCaptain } from "@/utils/_"
import { storageKeys, getLs, setLs, removeLs } from "@/utils/storage"

const initialState = {
  favoriteSessions: [],
  prefs: {
    send: "enter",
    isLiveAsideOpen: true,
  },
  unreadMessagesCount: 0,
  previousToken: "",
  registrationModel: null,
  team: null,
  likedNominants: [],
}

export const state = () => ({
  ...initialState,
})

export const getters = {
  user: (_state, _getters, rootState) => rootState.auth.user,

  favoriteSessions: state => state.favoriteSessions || [],
  // TODO: remove
  isFavorite: (_state, getters) => sessionAlias =>
    Boolean(getters.favoriteSessions.find(s => s.alias === sessionAlias)),
  isSessionFavorite: (_state, getters) => sessionAlias =>
    Boolean(getters.favoriteSessions.find(s => s.alias === sessionAlias)),

  prefs: state => state.prefs || initialState.prefs,

  isAdmin: (_state, getters) => isUserAdmin(getters.user),
  isCaptain: (_state, getters) => isUserCaptain(getters.user),
  isOrganizer: (_state, getters) =>
    getters.user?.status.alias === "organizer" && getters.isApproved,

  isImpersonalized: state => Boolean(state.previousToken),

  isApprovable: (_state, getters) => ["approvable", "approvable_paid"]
    .includes(getters.user?.status?.workflow),
  isPayable: (_state, getters) => ["paid", "approvable_paid"]
    .includes(getters.user?.status?.workflow),

  isApproved: (_state, getters) =>
    (getters.user?.states || []).includes("approved"),
  isPaid: (_state, getters) =>
    (getters.user?.states || []).includes("paid"),

  hasTeam: state => Boolean(state.team),

  likedNominantsByNom:
    state => groupBy(state.likedNominants, "nomination.title.current"),
}

export const mutations = {
  RESET_STATE (state) { Object.assign(state, initialState) },

  SET_FAVORITE_SESSIONS (state, sessions) { state.favoriteSessions = sessions },

  ADD_FAVORITE_SESSION (state, session) {
    state.favoriteSessions.push(session)
  },

  // TODO: remove
  DELETE_FAVORITE_SESSION (state, payload) {
    const index =
      state.favoriteSessions.findIndex(entry => entry.alias === payload)
    state.favoriteSessions.splice(index, 1)
  },
  REMOVE_FAVORITE_SESSION (state, sessionAlias) {
    const idx =
      state.favoriteSessions.findIndex(s => s.alias === sessionAlias)
    state.favoriteSessions.splice(idx, 1)
  },

  SET_PREFS (state, prefs) { state.prefs = prefs },

  SET_UNREAD_MESSAGES_COUNT (state, unreadMessagesCount) {
    state.unreadMessagesCount = unreadMessagesCount < 0 ? 0 : unreadMessagesCount
  },

  SET_PREVIOUS_TOKEN (state, token) { state.previousToken = token },

  SET_REGISTRATION_MODEL (state, registrationModel) {
    state.registrationModel = registrationModel
  },

  SET_TEAM (state, team) { state.team = team },

  SET_LIKED_NOMINANTS (state, nominants) {
    state.likedNominants = nominants
  },

  LIKE_NOMINANT (state, nominant) {
    state.likedNominants.push(nominant)
  },

  UNLIKE_NOMINANT (state, nominantId) {
    const idx = state.likedNominants.findIndex(n => n.id === nominantId)
    if (idx > -1) state.likedNominants.splice(idx, 1)
  },

  CHANGE_TEAM_NAME (state, name) {
    if (!name) return
    state.team.teamName = name
  },
}

export const actions = {
  async FETCH_FAVORITE_SESSIONS ({ commit }) {
    const [res, err] = await this.$api.program.getFavoriteSessions()
    if (err) console.error(err)
    else commit("SET_FAVORITE_SESSIONS", this.$api.helpers.getList(res))
  },

  // TODO: remove
  async ADD_FAVORITE_SESSION ({ commit }, session) {
    const [, err] = await this.$api.program.addFavoriteSession(session.alias)
    if (err) {
      console.error(err)
      return { errors: true }
    }
    commit("ADD_FAVORITE_SESSION", session)
    return { success: true }
  },
  async addFavoriteSession ({ commit }, session) {
    commit("ADD_FAVORITE_SESSION", session)
    const [, err] = await this.$api.program.addFavoriteSession(session.alias)
    if (err) {
      console.error(err)
      commit("REMOVE_FAVORITE_SESSION", sessionAlias)
    }
  },

  // TODO: remove
  async DELETE_FAVORITE_SESSION ({ commit }, sessionAlias) {
    const [, err] =
      await this.$api.program.removeFavoriteSession(sessionAlias)
    if (err) {
      console.error(err)
      return { errors: true }
    }
    commit("DELETE_FAVORITE_SESSION", sessionAlias)
    return { success: true }
  },
  async removeFavoriteSession ({ commit }, session) {
    const { alias } = session
    commit("REMOVE_FAVORITE_SESSION", alias)
    const [, err] =
      await this.$api.program.removeFavoriteSession(alias)
    if (err) {
      commit("ADD_FAVORITE_SESSION", session)
      console.error(err)
    }
  },

  FETCH_PREFS ({ commit }) {
    const prefs = getLs(storageKeys.prefs)
    if (prefs) commit("SET_PREFS", prefs)
  },

  UPDATE_PREFS ({ state, commit }, changedPrefs) {
    const newPrefs = { ...state.prefs, ...changedPrefs }
    commit("SET_PREFS", newPrefs)
    setLs(storageKeys.prefs, newPrefs)
  },

  async IMPERSONALIZE ({ commit, getters, dispatch }, token) {
    // if (!process.browser) return
    if (!getters.isImpersonalized) {
      const previousToken = this.$auth.strategy.token.get()
      if (previousToken) {
        commit("SET_PREVIOUS_TOKEN", previousToken)
        setLs(storageKeys.previousToken, previousToken)
      }
    }
    // this.$privateChatNotifications.stop()
    await this.$auth.setUserToken(token)
    // this.$privateChatNotifications.start()
    await dispatch("fetchTeam")
    await dispatch("fetchLikedNominants")
    // await dispatch("FETCH_FAVORITE_SESSIONS")
  },

  async EXIT_IMPERSONALIZATION ({ getters, state, commit, dispatch }) {
    // if (!process.browser) return
    if (!getters.isImpersonalized) return
    // this.$privateChatNotifications.stop()
    await this.$auth.setUserToken(state.previousToken)
    // this.$privateChatNotifications.start()
    await dispatch("fetchTeam")
    await dispatch("fetchLikedNominants")
    // await dispatch("FETCH_FAVORITE_SESSIONS")
    this.$router.push(this.localePath("/user/profile"))
    commit("SET_PREVIOUS_TOKEN", "")
    removeLs(storageKeys.previousToken)
  },

  async LOGOUT ({ getters, dispatch }) {
    if (getters.isImpersonalized) await dispatch("EXIT_IMPERSONALIZATION")
    else this.$auth.logout()
  },

  async fetchTeam ({ commit }) {
    if (!this.$auth.loggedIn) return
    const [res, err] = await this.$api.user.getTeam()
    if (err) {
      console.error(err)
      return
    }
    commit("SET_TEAM", res?.[0] || null)
  },

  async fetchLikedNominants ({ commit }) {
    const [res, err] = await this.$api.user.listLikedNominants()
    if (err) console.error(err)
    else commit("SET_LIKED_NOMINANTS",
      (res?.items || []).map(l => ({ ...l.nominant, likeId: l.id })))
  },

  async likeNominant ({ commit }, nominant) {
    commit("LIKE_NOMINANT", nominant)
    const [res, err] = await this.$api.user.likeNominant(nominant.id)
    if (err) {
      commit("UNLIKE_NOMINANT", nominant.id)
      console.error(err)
    } else {
      commit("UNLIKE_NOMINANT", nominant.id)
      commit("LIKE_NOMINANT", { ...nominant, likeId: res.id })
    }
  },

  async unlikeNominant ({ commit }, nominant) {
    commit("UNLIKE_NOMINANT", nominant.id)
    const [, err] =
      await this.$api.user.unlikeNominant(nominant.likeId)
    if (err) {
      commit("LIKE_NOMINANT", nominant)
      console.error(err)
    }
  },
}
