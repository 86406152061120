// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4x3rzjpL{background:var(--color-white);background:url(/landing/footer.png) center -5px/auto 90% no-repeat;border-top:2rem solid var(--color-blue);margin-top:var(--spacing-md);padding:var(--spacing-md);text-align:center}@media(max-width:667.98px){._4x3rzjpL{padding:var(--spacing-sm)}}.Jvc56TKF{align-items:center;display:flex;flex-direction:column;margin:0 auto;max-width:var(--content-width)}@media(max-width:947.98px){.Jvc56TKF{flex-direction:column}}.RXUsGxES{font-size:1.5rem;font-weight:600}.M1\\+203qb{font-size:1rem;font-weight:500}.zUyY4eAh{font-size:.63rem;margin:.3rem 0 2rem}.ZzBEDCI7{font-size:.88rem;font-weight:500}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "_4x3rzjpL",
	"content": "Jvc56TKF",
	"phone": "RXUsGxES",
	"text": "M1+203qb",
	"subtext": "zUyY4eAh",
	"copyright": "ZzBEDCI7"
};
module.exports = ___CSS_LOADER_EXPORT___;
