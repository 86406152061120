import { wrap, wrapGql } from "../_"

type UpdateUserData = any // TODO
type CreateUserData = any // TODO
type CreateUserQuickData = any // TODO

interface SendTokenData { email: string, status?: string }

const pfx = "/user"

export default (a: NuxtAxiosInstance) => ({
  getFavoriteUsers: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/favorite`,
      { ...cfg })),

  addFavoriteUser: (userId: string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/${userId}/favorite`, {},
      { ...cfg })),

  removeFavoriteUser: (userId: string, cfg?: AxiosConfig) => wrap(
    a.$delete(`${pfx}/${userId}/favorite`,
      { ...cfg })),

  getUsers: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}`,
      { cache: true, ...cfg })),

  updateUser: (data: UpdateUserData, cfg?: AxiosConfig) => wrap(
    a.$patch(`${pfx}`, data,
      { ...cfg })),

  getUser: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/my`,
      { ...cfg })),

  getCountries: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/countries`,
      { cache: true, ...cfg })),

  getColleague: (userId: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/colleague/${userId}`,
      { ...cfg })),

  updateColleague: (userId: string, data: UpdateUserData, cfg?: AxiosConfig) =>
    wrap(
      a.$patch(`${pfx}/colleague/${userId}`, data,
        { ...cfg })),

  createColleague: (data: CreateUserData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/colleague`, data,
      { ...cfg })),

  createUser: (data: CreateUserData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}`, data,
      { ...cfg })),

  sendToken: (data: SendTokenData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/registration-token`, data,
      { ...cfg })),

  getTokenData: (token: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/registration-token/${token}`,
      { ...cfg })),

  createUserQuick: (data: CreateUserQuickData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/quick-registration`, data,
      { ...cfg })),

  getPreviousData: (token: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/previous-user/${token}`,
      { ...cfg })),

  getA5KUsers: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/a5k`,
      { cache: true, ...cfg })),

  getRecommendedUsers: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/recommended`,
      { ...cfg })),

  getTeam: (cfg?: AxiosConfig) => wrapGql(
    a.$post("/graphql", {
      query: `
        query {
          listMyTeamByParticipants {
            id
            # creationTime
            # updateTime
            # author
            nominants {
              id
              # creationTime
              # updateTime
              # author
              nameNominant
              nomination {
                id
                title {
                  current
                }
              }
              typeNominant {
                title {
                  current
                }
              }
              formatNominant {
                id
                title {
                  current
                }
              }
              descriptionNominant
              phone
              email
              site
              region {
                region
                result
              }
              logo {
                url
              }
              year
            }
            participants {
              id
              firstName {
                current
              }
              lastName {
                current
              }
              # status {
              #   title {
              #     current
              #   }
              # }
              attributes
            }
            region
            teamName
            state
          }
        }
      `,
    }, { ...cfg }),
    "listMyTeamByParticipants",
  ),

  listLikedNominants: () => wrapGql(
    a.$post("/graphql", {
      query: `
        query {
          listMyLike (limit: 1000) {
            items {
              id
              nominant {
                id
                nomination {
                  title {
                    current
                  }
                }
              }
            }
          }
        }
      `,
    }),
    "listMyLike",
  ),

  likeNominant: (nominantId: string) => wrapGql(
    a.$post("/graphql", {
      query: `
        mutation ($nominantId: UUID!) {
          createLike (nominant: $nominantId) {
            id
          }
        }
      `,
      variables: { nominantId },
    }),
    "createLike",
  ),

  unlikeNominant: (likeId: string) => wrapGql(
    a.$post("/graphql", {
      query: `
        mutation ($likeId: UUID!) {
          deleteLike (id: $likeId)
        }
      `,
      variables: { likeId },
    }),
    "deleteLike",
  ),

  setUserCapitan: (value: "yes" | "no") => wrapGql(
    a.$post("/graphql", {
      query: `
        mutation ($value: String!) {
          setUserCapitan (value: $value)
        }
      `,
      variables: { value },
    }),
    "setUserCapitan",
  ),

  addSelfToTeam: (team: string) => wrapGql(
    a.$post("/graphql", {
      query: `
        mutation ($team: UUID!) {
          addSelfToTeam (team: $team)
        }
      `,
      variables: { team },
    }),
    "addSelfToTeam",
  ),

  createTeam: (teamName: string) => wrapGql(
    a.$post("/graphql", {
      query: `
        mutation ($teamName: String) {
          createTeam (teamName: $teamName) {
            id
          }
        }
      `,
      variables: { teamName },
    }),
    "createTeam",
  ),

  updateTeam: (id: string, teamName: string) => wrapGql(
    a.$post("/graphql", {
      query: `
        mutation (
          $id: UUID!
          $teamName: String
        ) {
          updateTeam (
            id: $id
            teamName: $teamName
          )
        }
      `,
      variables: { id, teamName },
    }),
    "updateTeam",
  ),

  moderateTeam: () => wrapGql(
    a.$post("/graphql", {
      query: `
        mutation {
          approveMyTeam {
            id
            # creationTime
            # updateTime
            # author
            nominants {
              id
              # creationTime
              # updateTime
              # author
              nameNominant
              nomination {
                title {
                  current
                }
              }
              typeNominant {
                title {
                  current
                }
              }
              formatNominant {
                title {
                  current
                }
              }
              descriptionNominant
              phone
              email
              site
              region {
                region
                result
              }
              logo {
                url
              }
              year
            }
            participants {
              id
              firstName {
                current
              }
              lastName {
                current
              }
              # status {
              #   title {
              #     current
              #   }
              # }
              attributes
            }
            region
            teamName
            state
          }
        }
      `,
    }),
    "approveMyTeam",
  ),
})
