// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Vo1wGoMN{display:flex;flex-direction:column;min-height:100vh}.V2I-u8Cp,.YCqzj97X{display:flex;flex-direction:column;flex-grow:1}.YCqzj97X{margin:0 auto;max-width:var(--content-width);width:100%}@media(max-width:1229.98px){.AnX983ej{padding:0 var(--spacing-md)}}@media(max-width:667.98px){.AnX983ej{padding:0 var(--spacing-sm)}}.kXFuP-sE{height:40vh;position:relative}.c7lCF0K5{height:100%;-o-object-fit:cover;object-fit:cover;width:100%}.zU0j7vQE{background:linear-gradient(var(--color-primary),transparent);height:100%;left:0;position:absolute;top:0;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "Vo1wGoMN",
	"content": "V2I-u8Cp",
	"inner": "YCqzj97X",
	"innerPadding": "AnX983ej",
	"banner": "kXFuP-sE",
	"bannerImage": "c7lCF0K5",
	"bannerOverlay": "zU0j7vQE"
};
module.exports = ___CSS_LOADER_EXPORT___;
