import { ApiFile } from "@/types/api/file"
import type { User } from "@/types/api/person"
import type { Message } from "@/types/api/chat"

export function changeUrl (url: string) {
  window.history.replaceState({}, "", url)
}

export const isUserAdmin = (user: User) => Boolean(user) && [
  "admin",
  "superadmin",
].some(r => user.roles.includes(r))

export const isUserCaptain = (user: User) => Boolean(user) && user.capitan === "yes"

export function getFailToken (
  error: NuxtContext["error"], i18n: NuxtContext["i18n"],
) {
  return () => error({
    statusCode: 404, message: i18n.t("error.error") as string,
  })
}

export function onLoad (cb: Function, delay = 1) {
  const delayedCb = () => setTimeout(() => cb(), delay)
  if (document.readyState === "complete") delayedCb()
  else window.addEventListener("load", delayedCb, { once: true })
}

export function parseEvent (_?: string): string {
  return "odobreno-premia-deti-23"
  // const hostParts = host.split(".")
  // return hostParts[hostParts.length - 3] || "odobreno-premia-deti-23"
}

export function parseFileName (path: ApiFile["path"]): string {
  if (!path) return ""
  const parts = path.split("/")
  return parts[parts.length - 1]
}

export function msgToStr (msg: Message): string {
  const { type, content } = msg
  // @ts-ignore
  if (type === "text") return content
  // @ts-ignore
  if (type === "file") return content.description || content.file
  return ""
}

export function getCookie (key: string): string | null {
  try {
    const cookie = document.cookie.split(";")
    if (!cookie || !cookie.length) return null
    for (const c of cookie)
      if (c.includes(key)) {
        const code = c.split("=")[1]
        return code
      }
    return null
  } catch (error) {
    return null
  }
}
