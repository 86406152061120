import { wrapGql } from "../_"
import type { Filters } from "@/types/api/gql"

export default (a: NuxtAxiosInstance) => ({
  getNominantRating: (nominantId: string, cfg?: AxiosConfig) => wrapGql(
    a.$post("/graphql", {
      query: `
        query ($nominantId: UUID!) {
          listMyRating (
            filters: [{ name: "nominant.id", value: $nominantId }]
          ) {
            items {
              nominant {
                id
              }
            }
          }
        }
      `,
      variables: { nominantId },
    }, { ...cfg }),
    "listMyRating",
  ),

  createRating: (variables: Record<string, any>, cfg?: AxiosConfig) => wrapGql(
    a.$post("/graphql", {
      query: `
        mutation(
          $status: UUID!
          $question72: [String!]
          $question42: String
          $formatNominant: UUID!
          $question76: [String!]
          $nomination: UUID!
          $question79: [String!]
          $nominant: UUID!
          $question77: [String!]
          $question4: String
          $question1: String
          $question16: String
          $question29: String
          $question55: String
          $question17: String
          $question30: String
          $question2: String
          $question57: String
          $question5: String
          $question50: String
          $question56: String
          $question18: String
          $question19: String
          $question3: String
          $question31: String
          $question6: String
          $question32: String
          $question71: [String!]
          $question8: String
          $question33: String
          $question38: String
          $question39: Float
          $question40: String
          $question41: String
          $question34: String
          $question9: String
          $question43: String
          $question47: String
          $question49: String
          $question22: String
          $question35: String
          $question10: String
          $question53: String
          $question54: String
          $question36: String
          $question37: String
          $question100: [String!]
          $question70: [String!]
          $question75: [String!]
          $question78: [String!]
          $question73: [String!]
          $vozmoznost: Boolean
        ) {
          createRating (
            status: $status
            question72: $question72
            question42: $question42
            formatNominant: $formatNominant
            question76: $question76
            nomination: $nomination
            question79: $question79
            nominant: $nominant
            question77: $question77
            question4: $question4
            question1: $question1
            question16: $question16
            question29: $question29
            question55: $question55
            question17: $question17
            question30: $question30
            question2: $question2
            question57: $question57
            question5: $question5
            question50: $question50
            question56: $question56
            question18: $question18
            question19: $question19
            question3: $question3
            question31: $question31
            question6: $question6
            question32: $question32
            question71: $question71
            question8: $question8
            question33: $question33
            question38: $question38
            question39: $question39
            question40: $question40
            question41: $question41
            question34: $question34
            question9: $question9
            question43: $question43
            question47: $question47
            question49: $question49
            question22: $question22
            question35: $question35
            question10: $question10
            question53: $question53
            question54: $question54
            question36: $question36
            question37: $question37
            question100: $question100
            question70: $question70
            question75: $question75
            question78: $question78
            question73: $question73
            vozmoznost: $vozmoznost
          )
        }
      `,
      variables,
    }, { ...cfg }),
    "createRating",
  ),

  listNominant: (page = 1, filters: Filters = []) => wrapGql(
    a.$post("/graphql", {
      query: `
        query (
          $page: Int!
          $filters: [Filter!]
        ) {
          listNominant (
            page: $page
            limit: 12
            filters: $filters
          ) {
            items {
              dopusk
              id
              nameNominant
              nomination {
                id
                title {
                  current
                }
              }
              typeNominant {
                id
                title {
                  current
                }
              }
              formatNominant {
                id
                title {
                  current
                }
              }
              # descriptionNominant
              # phone
              # email
              # site
              region {
                region
                city
                latitude
                longitude
              }
              logo {
                url
              }
              finalist
              year
            }
            pagination {
              page
              pages
              limit
              total
            }
          }
        }
      `,
      variables: { page, filters },
    }),
    "listNominant",
  ),

  getNominant: (id: string) => wrapGql(
    a.$post("/graphql", {
      query: `
        query (
          $id: UUID!
        ) {
          listNominant (
            limit: 1
            filters: [{ name: "id", value: $id }]
          ) {
            items {
              id
              nameNominant
              nomination {
                title {
                  current
                }
              }
              typeNominant {
                title {
                  current
                }
              }
              formatNominant {
                title {
                  current
                }
              }
              descriptionNominant
              phone
              email
              site
              region {
                region
                result
              }
              logo {
                url
              }
              finalist
              year
            }
          }
        }
      `,
      variables: { id },
    }),
    "listNominant",
  ),

  listNominantRegion: () => wrapGql(
    a.$post("/graphql", {
      query: `
        query {
          listNominantRegion
        }
      `,
    }),
    "listNominantRegion",
  ),
})
